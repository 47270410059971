import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

function Footer() {
    return (
        <div className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 justify-content-center text-center">
                    <h5 className="footer-title" style={{color: 'black'}}><b>USEFUL LINKS</b></h5>
                        <ul className="list-unstyled">
                            <li><a href="https://www.cpta.ab.ca/">Alberta College of Physiotherapy</a></li>
                            <li><a href="https://visitbraggcreek.com/">Visit Bragg Creek</a></li>
                            <li><a href="https://www.redwoodmeadows.ab.ca/">Redwood Meadows Townsite</a></li>
                            <li><a href="https://cuevasmedek.com/">What is CME</a></li>
                        </ul>
                    </div>
                    <div className="col-md-4 justify-content-center text-center">
                        <h5 className="footer-title" style={{color: 'black'}}><b>NAVIGATION</b></h5>
                        <ul className="list-unstyled">
                            <li><a href="/">Home</a></li>
                            <li><a href="/services">Services</a></li>
                            <li><a href="/about">About</a></li>
                            <li><a href="mailto:info@peakandpinephysio.ca">Contact</a></li>
                        </ul>
                    </div>
                    <div className="col-md-4 justify-content-center text-center">
                        <h5 className="footer-title" style={{color: 'black'}}><b>CONTACT INFO</b></h5>
                        <p>Svenje Duffield, Physiotherapist</p>
                        <p>9 Redwood Meadows Crt, Redwood Meadows</p>
                        <p><a href="mailto:info@peakandpinephysio.ca">info@peakandpinephysio.ca</a></p>
                        <p>368-887-9822</p>
                        <a href="https://www.instagram.com/svenje.thephysio/"><p className='social-icon'><FontAwesomeIcon icon={faInstagram} size="2xl" /> Instagram</p></a>
                    </div>
                </div>
                <div className="row justify-content-center text-center mt-4">
                    <div className="col-md-12">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/logo_2.png`} alt="About Us" className="img footer-logo mb-4" />
                        <p className='footer-legal'><b>&copy; 2023 Peak And Pine Physio. All Rights Reserved.</b></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
