import React from 'react';

const AboutUsBanner = () => {
    return (
        <div className="about-us-banner">
            <img src={`${process.env.PUBLIC_URL}/assets/images/sheet.jpg`} alt="About Us" className="img-fluid banner-image" />
            <div className="banner-text">
                <h1>About Me</h1>
            </div>
        </div>
    );
}

export default AboutUsBanner;
