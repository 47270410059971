import React from 'react';
import Navbar from '../components/navbar/navbar';
import Footer from '../components/footer/footer';
import ServicesComponent from '../components/modules/services';

const Services = () => {
    return (
        <div className="services-container">
            {/* Navbar */}
            <Navbar />
            
            {/* Main Content */}
            <div className="services-content pt-5 mt-5">
                < ServicesComponent />
                {/* Add your other content for services here */}
            </div>
            
            {/* Footer */}
            <Footer />
        </div>
    );
}

export default Services;
