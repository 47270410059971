import React from 'react';

function Contact() {
    return (
        <div className="contact container py-5">
            <div className="row">
                <div className="col-lg-8 mx-auto text-center">
                    <h2 className="mb-4">Contact Me</h2>
                    <p className="mb-5">Have a question? Don't hesitate to get in touch!</p>
                    <a href="mailto:info@peakandpinephysio.ca" style={{ textDecoration: 'none' }}><button className="btn btn-primary btn-lg">Contact</button></a>
                </div>
            </div>
        </div>
    );
}

export default Contact;
