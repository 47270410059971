import React from 'react';
import { Fade } from 'react-awesome-reveal';

function Services() {
    const paediatricItems = [
        {
            title: 'Neurodevelopmental Therapy',
            description: [
                'NDT: Neurodevelopmental Therapy (sometimes called Bobath Therapy) is a treatment approach that involves facilitation of movement and alignment strategies to enable a child to gain postural control and meet milestones.'
            ],
            image: `${process.env.PUBLIC_URL}/assets/images/services/NDT.jpg`
        },
        {
            title: 'Sports & Orthopaedic Therapy - For The Growing Body',
            description: 'Children grow (well Hallelujah!). But often that means the more active kiddos experience knee, heel or hip pain. The right treatment coupled with the right home therapy program and advice, will help them be pain free and back on the field or ice in as little time as possible. Worried about your tween/ teen’s spine? Are they just slumpy coach potatoes or developing a scoliosis? Let’s check it out and make sure - an ounce of prevention is worth a pound of cure!',
            image: `${process.env.PUBLIC_URL}/assets/images/services/sportsandorthoyouth.jpg`
        },
        {
            title: 'Infant (Baby) Development',
            description: [
                'Why is my baby not sitting by himself yet? Wait, is he even supposed to sit already?! So much conflicting info giving you so many reasons to lie awake at night! I can help! There’s countless evidence to say that EARLY INTERVENTION IS KEY to successful development, should there be a delay. Let’s do an objective assessment (I’m a sucker for evidence based practice!) so that we can start with neurodevelopmental therapy ASAP if it is needed. Sleepless nights be gone! (Lol, who are we kidding, you have a baby - no sleep for you!).'
            ],
            image: `${process.env.PUBLIC_URL}/assets/images/services/infant.jpg`
        },
        {
            title: 'Cuevas Medek Exercises (CME):',
            description: [
                'A dynamic method of motor stimulation, CME provokes a postural response from a child using distal points of control. This approach leverages a combination of boxes and beams to extract the most effective responses.',
            ],
            image: `${process.env.PUBLIC_URL}/assets/images/services/PAEDIATRIC_PHYSIOTHERAPY.jpg`
        },
        {
            title: 'Neuromuscular Electrical Stimulation',
            description: [
                'If applicable Neuromuscular electrical stimulation, TheraTogs and taping may also be implemented in your childs session.'
            ],
            image: `${process.env.PUBLIC_URL}/assets/images/services/default_neuro.jpg`
        },
    ]

    const orthopaedicItems = [
        {
            title: 'Orthopaedic (Muscle & Joint Therapy)',
            description: 'Whether you’ve sprained your ankle for the umpteenth time, or slept on the wrong pillow (again!), I can help! Through comprehensive assessment and a range of treatment modalities to choose from, you’ll be back to doing the things you love in as little time as possible!',
            image: `${process.env.PUBLIC_URL}/assets/images/services/neuro.png`
        }
    ]

    const dryNeedlingItems = [
        {
            title: 'Dry Needling / IMS',
            description: 'Please can you just stick a needle into this muscle? YES I can, but ONLY if it is the appropriate treatment option for your specific injury, which I can easily determine through thorough evaluation. IMS / Dry Needling is an effective treatment option for many musculoskeletal problems but it is definitely not the right treatment for every ache and pain out there! Scared of needles? No problem, many tricks up these sleeves!',
            image: `${process.env.PUBLIC_URL}/assets/images/services/dry_needling.jpg`,
        }
    ]

    const neuroItems = [
        {
            title: 'Neuro Physio For Adults',
            description: "Life changes so fast when you have had a neurological diagnosis i.e. a stroke or a neurodegenerative disease. Allow me to help you and your loved ones adapt to all those changes in as functional and independent a way as possible. This can include home / other site visits; you don't need to roll with the punches if you effectively punch back!",
            image: `${process.env.PUBLIC_URL}/assets/images/services/musclesjoint.png`,
        }
    ]

/*
    const serviceItems = [
        {
            title: 'PAEDIATRIC PHYSIOTHERAPY',
            description: [
                'The comprehensive assessment and treatment of children is not only my passion but also my first love. This process will involve a blend of intense hard work and fun. Drawing from my extensive experience'
            ],
            image: `${process.env.PUBLIC_URL}/assets/images/services/default_paediatric.jpg`,
            link: '#paediatric'
        },
        {
            title: 'DRY NEEDLING / IMS',
            description: 'Please can you just stick a needle into this muscle? YES I can, but ONLY if it is the appropriate treatment option for your specific injury, which I can easily determine through thorough evaluation. IMS / Dry Needling is an effective treatment option for many musculoskeletal problems but it is definitely not the right treatment for every ache and pain out there! Scared of needles? No problem, many tricks up these sleeves!',
            image: `${process.env.PUBLIC_URL}/assets/images/services/dry_needling.jpg`,
            link: 'https://peakandpinephysio.janeapp.com/'
        },
        {
            title: 'ORTHOPAEDIC THERAPY',
            description: 'Whether you are suffering through the upteenth time you have rolled that ankle, or slept on the wrong pillow (again), I can help. This will involve a combination of hands on therapy (mobilisations, soft tissue treatment and other things), a tailored exercise program and the latest strategies in acute and chronic pain management.',
            image: `${process.env.PUBLIC_URL}/assets/images/services/default_ortho.jpg`,
            link: '#orthopaedic'
        }
    ];

    */
    
    return (
        <div className="services container-fluid py-5">
            <h1 className='text-center services-title'>SERVICES</h1>
            {/* 
            {serviceItems.map((service, index) => (
    <Fade triggerOnce key={index} delay={200}>
        <div className="row align-items-center my-5">
            <div className={`col-md-6 ${index % 2 !== 0 ? 'order-md-2' : ''}`}>
                <img src={service.image} alt={service.title} className="img-fluid service-image" />
            </div>
            <div className="col-md-6">
                <h2 className="service-title mt-3">{service.title}</h2>
                {Array.isArray(service.description) ? (
                    service.description.map((desc, descIndex) => (
                        <p key={descIndex} className="service-description">{desc}</p>
                    ))
                ) : (
                    <p className="service-description">{service.description}</p>
                )}
                <a 
                    href={service.link} 
                    className="btn service-cta-button"
                    target={service.title === 'DRY NEEDLING / IMS' ? '_blank' : '_self'}
                    rel={service.title === 'DRY NEEDLING / IMS' ? 'noopener noreferrer' : ''}
                >
                    {service.title === 'DRY NEEDLING / IMS' ? 'BOOK NOW' : 'VIEW DETAILS'}
                </a>
            </div>
        </div>
    </Fade>
))}
*/}
            <h1 id="paediatric" className='text-center anchor'>PAEDIATRIC PHYSIOTHERAPY</h1>
            <h1 className='text-center paediatric-title'>PAEDIATRIC PHYSIOTHERAPY</h1>
            {paediatricItems.map((service, index) => (
                <Fade triggerOnce key={index} delay={200}>
                    <div className="row align-items-center my-5">
                        <div className={`col-md-6 ${index % 2 !== 0 ? 'order-md-2' : ''}`}>
                            <img src={service.image} alt={service.title} className="img-fluid service-image" />
                        </div>
                        <div className="col-md-6">
                            <h2 className="service-title mt-3">{service.title}</h2>
                            {Array.isArray(service.description) ? (
                                service.description.map((desc, descIndex) => (
                                    <p key={descIndex} className="service-description">{desc}</p>
                                ))
                            ) : (
                                <p className="service-description">{service.description}</p>
                            )}
                        </div>
                    </div>
                </Fade>
            ))}
            <h1 id="orthopaedic" className='text-center anchor'>MUSCLE AND JOINT THERAPY</h1>
            <h1 className='text-center paediatric-title'>MUSCLE AND JOINT THERAPY</h1>
            {orthopaedicItems.map((service, index) => (
                <Fade triggerOnce key={index} delay={200}>
                    <div className="row align-items-center my-5">
                        <div className={`col-md-6 ${index % 2 === 0 ? 'order-md-2' : ''}`}>
                            <img src={service.image} alt={service.title} className="img-fluid service-image" />
                        </div>
                        <div className="col-md-6">
                            <h2 className="service-title mt-3">{service.title}</h2>
                            {Array.isArray(service.description) ? (
                                service.description.map((desc, descIndex) => (
                                    <p key={descIndex} className="service-description">{desc}</p>
                                ))
                            ) : (
                                <p className="service-description">{service.description}</p>
                            )}
                        </div>
                    </div>
                </Fade>
            ))}
            <h1 id="dryNeedle" className='text-center anchor'>DRY NEEDLING / IMS</h1>
            <h1 className='text-center paediatric-title'>DRY NEEDLING / IMS</h1>
            {dryNeedlingItems.map((service, index) => (
                <Fade triggerOnce key={index} delay={200}>
                    <div className="row align-items-center my-5">
                        <div className={`col-md-6 ${index % 2 !== 0 ? 'order-md-2' : ''}`}>
                            <img src={service.image} alt={service.title} className="img-fluid service-image" />
                        </div>
                        <div className="col-md-6">
                            <h2 className="service-title mt-3">{service.title}</h2>
                            {Array.isArray(service.description) ? (
                                service.description.map((desc, descIndex) => (
                                    <p key={descIndex} className="service-description">{desc}</p>
                                ))
                            ) : (
                                <p className="service-description">{service.description}</p>
                            )}
                        </div>
                    </div>
                </Fade>
            ))}
            <h1 id="dryNeedle" className='text-center anchor'>NEUROLOGICAL PHYSIOTHERAPY</h1>
            <h1 className='text-center paediatric-title'>NEUROLOGICAL PHYSIOTHERAPY</h1>
            {neuroItems.map((service, index) => (
                <Fade triggerOnce key={index} delay={200}>
                    <div className="row align-items-center my-5">
                        <div className={`col-md-6 ${index % 2 === 0 ? 'order-md-2' : ''}`}>
                            <img src={service.image} alt={service.title} className="img-fluid service-image" />
                        </div>
                        <div className="col-md-6">
                            <h2 className="service-title mt-3">{service.title}</h2>
                            {Array.isArray(service.description) ? (
                                service.description.map((desc, descIndex) => (
                                    <p key={descIndex} className="service-description">{desc}</p>
                                ))
                            ) : (
                                <p className="service-description">{service.description}</p>
                            )}
                        </div>
                    </div>
                </Fade>
            ))}
        </div>
    );
}

export default Services;