import React from 'react';
import Navbar from '../components/navbar/navbar';
import Banner from '../components/modules/about-us-banner';
import Footer from '../components/footer/footer';
import Contact from '../components/modules/contact';
import { Fade } from 'react-awesome-reveal';

const AboutUs = () => {
    return (
        <div className="about-us-container">
            <Navbar />

            {/* Banner */}
            <Banner />
            <div className="container-fluid about-us-content">
              <div className="row about-us-row">
                <div className="col about-us-col">
                  <Fade triggerOnce delay={200}>
                  <h1 className="about-us-title">Get to know Svenje!</h1>
                  <p className="about-us-desc">I can honestly say that physiotherapy is not just a job for me. I count myself extremely lucky to feel passionate about the differences I am
fortunate to see every single day. From a young age I knew I loved people, but in particular I was drawn to people who were suffering, with the intent of helping. With this in my soul, I decided to go study physiotherapy and completed my degree at the University of the Free State (South Africa) in 2010. I then went on to pursue a full dissertation Masters degree in wheelchair seating. As a clinic owner in South Africa, my focus was on treating infants and children with neurological conditions.<br /><br /> 

After moving to Canada, I worked at an orthopaedic and sports injury clinic, where I got to  brush up on my knowledge of all things pain and sport related. My passion has always been improving the quality of life for my patients and their families. To be a part of someone reaching a goal or a milestone is an intimate privilege that I don’t take lightly. 

Outside of work, I am a proud parent of three beautiful children, a blue heeler and a border collie (as well as two cats - it’s a zoo I know!). We enjoy skiing, biking, and running long distances on top of high things like mountains. 
</p>
</Fade>
                </div>
                <div class="col-1"></div>
                <div className="col about-us-col">
                  <Fade triggerOnce delay={350}>
                  <img src={`${process.env.PUBLIC_URL}/assets/images/team/svenje.jpg`} alt="About Us" className="img-fluid about-us-img" />
                  </Fade>
                </div>
              </div>
            </div>
            <Contact />

            {/* Footer */}
            <Footer />
        </div>
    );
}

export default AboutUs;
