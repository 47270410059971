import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => setMenuOpen(!menuOpen);

    return (
        <nav className="navbar">
<div className="nav-logo d-flex align-items-center">
    <img src={`${process.env.PUBLIC_URL}/assets/images/logo_5.png`} alt="About Us" className="img-fluid nav-logo-image" />
    <a href="https://peakandpinephysio.janeapp.com" className="mobile-only book-now-btn-mobile" target='_blank' rel='noreferrer' onClick={toggleMenu}>Book Online</a>
</div>

            
            <div className={`menu-icon ${menuOpen ? 'active' : ''}`} onClick={toggleMenu}>
                <div></div>
                <div></div>
                <div></div>
            </div>

            <ul className={`nav-links ${menuOpen ? 'open' : ''}`}>
                <li>
                    <Link to="/" onClick={toggleMenu}>Home</Link>
                </li>
                <li>
                    <Link to="/services" onClick={toggleMenu}>Services</Link>
                </li>
                <li>
                    <Link to="/about" onClick={toggleMenu}>About</Link>
                </li>
                <li className="mobile-only">
                    <a href="https://peakandpinephysio.janeapp.com" target='_blank' className="book-now-btn p-2" rel='noreferrer' onClick={toggleMenu}>Book Online</a>
                </li>
                {/*
                <li>
                    <Link to="/team" onClick={toggleMenu}>Team</Link>
                </li>
                */}
            </ul>

            <a href="https://peakandpinephysio.janeapp.com" target='_blank' rel='noreferrer'><button className="book-now-btn">BOOK ONLINE</button></a>
        </nav>
    );
}

export default Navbar;
