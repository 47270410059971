import React, { useState } from 'react';

const FAQ = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const questionsAnswers = [
        {
            question: 'Are you a FSCD Provider?',
            answer: 'Yes, I am an accredited FSCD provider, offering physiotherapy services to children under the Family Support for Children with Disabilities program.',
        },
        {
            question: 'Do you direct bill to insurance?',
            answer: 'Yes, I offer direct billing to various insurance companies for added convenience to my clients.',
        },
        {
            question: 'What are your fees?',
            answer: '$140 for 60 minute assessment and treatment or $90 for a 30 minute follow up',
        },
    ];

    const handleClick = index => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    return (
        <div className="faq-container justify-content-center text-center mt-5 mb-5">
            <h2 className="faq-title">FAQ</h2>
            <div className="faq-list">
                {questionsAnswers.map((item, index) => (
                    <div className={`faq-item ${activeIndex === index ? 'active' : ''}`} key={index} onClick={() => handleClick(index)}>
                        <div className="faq-question">
                            <span>{item.question}</span>
                            <span className="faq-icon">{activeIndex === index ? '-' : '+'}</span>
                        </div>
                        <div className="faq-answer">
                            <p>{item.answer}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FAQ;
