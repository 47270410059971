import React from 'react';

function Banner() {
    return (
        <div className="banner container-fluid d-flex align-items-center justify-content-center">
            <img src={`${process.env.PUBLIC_URL}/assets/images/lifestyle.png`} alt="Peak & Pine Banner" className="img-fluid-banner" />
            <div className="text-content position-absolute text-white">
                <h1 className="banner-title">It may seem improbable,<br/>but it's not impossible!</h1>
            </div>
            <div className="overlay-text position-absolute text-dark">
                <p>Bears can't fly! Or can they? What seems impossible may in fact just be improbable. As a physio, I have so often heard it said that "they" said she would never walk, run again, her shoulder would never be the same again or that it would be impossible for him to live a full life.<br /><br />Well, if I had ten bucks for every time I have seen a patient prove "them" wrong, I'd be on a safari in Kenya right now. Making the improbable possible for little and big patients have been my absolute favourite part of being a Physio. Hence, the flying bear mission statement was born.</p>
            </div>
        </div>
    );
}

export default Banner;
