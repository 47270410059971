import React from 'react';
import '../assets/styles/main.css';
import '../assets/styles/navbar.css';
import '../assets/styles/banner.css';
import '../assets/styles/about-us-banner.css';
import '../assets/styles/about.css';
import '../assets/styles/services.css';
import '../assets/styles/testimonials.css';
import '../assets/styles/contact.css';
import '../assets/styles/footer/footer.css';
import '../assets/styles/services-banner.css';
import '../assets/styles/faq.css';
import Navbar from '../components/navbar/navbar';
import Banner from '../components/modules/banner';
import Services from '../components/modules/services';
import Contact from '../components/modules/contact';
import Footer from '../components/footer/footer';
import FAQ from '../components/modules/faq';
import { Fade } from 'react-awesome-reveal';

const Homepage = () => {
  return (
    <div className="homepage-container">
      <Navbar />

      {/* Hero Section */}
      {/* Wrapping the Banner component with the Fade animation */}
      <Fade triggerOnce>
        <Banner />
      </Fade>

      {/* Services CTA */}
        <Services />

      {/* Contact CTA */}
      {/* Delay prop can be used to delay the animation */}
      <Fade triggerOnce delay={200}>
        <Contact />
      </Fade>

      <Fade triggerOnce delay={200}>
        <FAQ />
      </Fade>

      {/* Footer */}
      {/* For the footer, we might not need an animation, but it's your choice */}
      <Footer />
    </div>
  );
}

export default Homepage;
